import React, { Fragment } from 'react';

import Hero from '../components/home/hero';
import Why from '../components/home/why';
import Why2 from '../components/home/why-2';
import How from '../components/home/how';
import Reviews from '../components/home/reviews';

import Properties from '../components/home/properties';
import About from '../components/home/about';
import OfficeInfo from '../components/home/office-info';
import Contact from '../components/contact';

import bgReview from '../images/pexels-cottonbro-4039184.jpg';

const itemsReview = [
  {
    id: null,
    src:"/team-6.jpg",
    review: "Excelente grupo de profesionales. Mi familia y yo estamos muy felices de haber dejado nuestra propiedad en sus manos. ¡Muchas gracias!",
    author: "Igor Venegas"
  },                      
  {
    id: null,
    src:"/team-2.jpg",
    review: "Muy eficientes. Es impresionante lo rápido que gestionaron mi proceso de venta y me asesoraron perfectamente en todo momento.",
    author: "Felipe Godoy"
  },
  {
    id: null,
    src:"/team-3.jpg",
    review: "¡Me ha parecido fantástico! Me han entregado una valorización gratuita en cosa de horas y con eso pudimos determinar el precio de venta de mi departamento. Realmente te hacen la vida más fácil.",
    author: "Linda Bellone"
  },      
]

export default ()=> {

  return(
    <Fragment>
      <Hero />
      <Why />
      <Why2 />
      <How />
      {/*<Reviews
        items={itemsReview}
        bgImg={bgReview}
        title="ambiadopropiedades@nexxos.cl es reconocido por su rapidez, agilidad y cercanía."
  />*/}
       <Properties /> 
      {/* <About /> */}
      {/* <OfficeInfo /> */}
      <Contact />
    </Fragment>
  )
}