import React, { useContext } from 'react';
import context from '../../context';
import styled from 'styled-components';
import AniLink from "gatsby-plugin-transition-link/AniLink";
import { Container, Row, Col } from 'react-bootstrap';

import { Section, Button } from '../../styled-components';

const SectionCustom = styled(Section)`
  background-color: ${props=> props.theme.primaryColor};
`
const InfoCont = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  max-width: 540px;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;    
  padding-top: 1rem;  
  color: #fff;
  @media (min-width: 768px){
    max-width: 720px;
    padding: 2rem 15px;
  }    
  @media (min-width: 992px){
    max-width: 400px;
    padding: 2rem 15px;
  }
  @media (min-width: 1200px){
    max-width: 570px;
    padding: 2rem 15px;
  }  
`
const Title = styled.h2`

`
const SubTitle = styled.p`

`
const StepsUl = styled.ol`
  padding-left: 1rem;
`
const StepLi = styled.li`
  a{
    color: #fff;
    text-decoration: underline !important;
  }
  p{
    font-size: .8rem;
  }
`
const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
`

export default ()=> {
  const state = useContext(context);
  return(
    <SectionCustom>
      <Row noGutters>
        <Col xs={12} md={12} lg={6}>
          <InfoCont>
            <Title>
              ¿Quieres publicar tu propiedad con nosotros?
            </Title>
            <SubTitle>
              ¡Claro! Reducimos todo a 3 simples pasos 
            </SubTitle>
            <StepsUl>
              <StepLi>
                <h6>
                  Valorizamos tu propiedad online
                </h6>
                <p>
                  Solo debes completar el formulario en <a href="http://formulario.cl">www.formulario.cl</a> y ya sabrás en cuanto podrías vender tu casa. 
                </p>
              </StepLi>
              <StepLi>
                <h6>
                  Contactamos contigo y llegamos a un acuerdo de venta
                </h6>
                <p>
                  Déjanos tu contacto y en breve te llamaremos para concretar los últimos detalles de la venta de tu inmueble.
                </p>
              </StepLi>
              <StepLi>
                <h6>
                  Vendemos tu propiedad
                </h6>
                <p>
                 Un corredor especializado de tu zona se encargará de vender tu propiedad rápidamente y al mejor precio de mercado, brindándote todo el soporte que necesites. 
                </p>
              </StepLi>                            
            </StepsUl>
            <AniLink paintDrip hex={state.primaryColor} to="/contact" duration={.5}>
              <Button >
                Contacto
              </Button>
            </AniLink>
          </InfoCont>
        </Col>
        <Col xs={12} md={12} lg={6}>
          <Image src={state.home.about.banner.image} />
        </Col>
      </Row>
    </SectionCustom>
  )
}