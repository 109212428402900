import React from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from 'react-bootstrap';
import { Section } from '../../styled-components';
import { v1 as uuid } from 'uuid';

import step1 from '../../images/step-1.png';
import step2 from '../../images/step-2.png';
import step3 from '../../images/step-3.png';
import step4 from '../../images/step-4.png';

const stepTO = [
  {
    id: uuid(),
    src: step1,
    title: "Tecnología avanzada y profesional",
    description: "Obtienes la valorización de tu propiedad.",
  },
  {
    id: uuid(),
    src: step2,
    title: "Valorización comercial sin costo",
    description: "Te contactamos para agendar una visita.",
  },
  {
    id: uuid(),
    src: step3,
    title: "Asesoría legal",
    description: "Llegamos a un acuerdo fijando todos los aspectos. ",
  },
  {
    id: uuid(),
    src: step4,
    title: "Financiamiento",
    description: "Te relajas y dejas la venta de tu casa en nuestras manos.",
  },         
]

const SectionCustom = styled(Section)`
  margin: 10rem 0;
`

const Title = styled.h2`
  text-align: center;
  margin-bottom: 1rem;
  @media(min-width: 768px){
    //max-width: 50%;
  }  
`
const Red = styled.span`
  color: ${props => props.theme.primaryColor};  
`
const Paragraph = styled.p`
  //width: 70%;
  text-align: center;
  @media(min-width: 768px){
    
  }
`
const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  @media(min-width: 768px){
    flex-direction: row;
    margin: 4rem 0;
  }
`
const Item = styled.li`
  flex: 1;
  display: flex;
  justify-content: center;
`
const ServiceCont = styled.div`
  width: 100%;
  position: relative;
`
const LineCont = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-height: 10rem;
  //border: 1px solid green;
  @media(min-width: 768px){
    min-height: 15rem;
    justify-content: center;
  }
`
const Line = styled.div`
  background-color: ${props => props.theme.primaryColor};
  height: 10rem;
  width: 10px;
  @media(min-width: 768px){
    width: 100%;
    height: 8px;
  }
`
const Circle = styled.div`
  background-color: ${props => props.theme.primaryColor};
  width: 20px;
  height: 20px;
  border-radius: 50%;
  position: absolute;
  //top: -10px;
  left: -10px;
  @media(min-width: 768px){
    left: auto;
  }

`
const Info = styled.div`
  width: 100%;
  height: 100%;
  //border: 1px solid red;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  @media(min-width: 768px){
    align-items: ${props => !props.down ? "flex-end" : "flex-start"};
  }
  &::before{
    content: " ";
    width: 40%;
    min-height: 100px;
    position: absolute;
    //top: 12.5%;
    //left: 0;
    background-image: url("${props => props.src}");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    @media(min-width: 768px){
      width: 40%;
    }
  }
  &::after{
    content: "${props => props.description}";
    background-color: ${props => !props.down ? "hsl(3, 55%, 90%)" : "#fff"};
    //color: ${props => !props.down ? "#fff" : "hsl(3, 55%, 85%)"};
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
    opacity:0;
    transition: 250ms ease;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    text-align: center;
    border: 1px solid #ddd;
    font-weight: bold;
    border: 1px solid ${props => props.theme.primaryColor};
    box-shadow: 0px 1px 1px rgba(0, 0, 0, .12),
                0px 2px 2px rgba(0, 0, 0, .12),
                0px 4px 4px rgba(0, 0, 0, .12),
                0px 8px 8px rgba(0, 0, 0, .12),
                0px 16px 16px rgba(0, 0, 0, .12);
    ${ServiceCont}:hover & {
      opacity: 1;
    }
  }
`

export default ()=> {

  return(
    <SectionCustom>
      <Container>
        <Title>
          ¿Te gustaría <Red>vender tu propiedad</Red> con nosotros?
        </Title>
        <Paragraph>
          Es muy simple, reducimos todo a 4 simples pasos
        </Paragraph>
        <List>
          {
            stepTO.map((item, index)=> (
              <Item>
                <ServiceCont>
                  <Info src={item.src} down={index % 2 === 0} description={item.description} />
                  <LineCont>
                    <Line />
                    <Circle />
                  </LineCont>
                </ServiceCont>              
              </Item>
            ))
          }          
        </List>
      </Container>
    </SectionCustom>
  )
}