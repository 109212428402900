import React from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from 'react-bootstrap';
import { Section } from '../../styled-components';
import ReviewsCarousel from '../carousels/reviews';
import { ClasiQuote } from '../../icons';

const SectionCustom = styled(Section)`
  background-image: linear-gradient(rgba(0, 0, 0, .5), rgba(0, 0, 0, .5)), url(${props => props.bgImg});
  background-size: cover;
  background-position: center;
  padding: 4rem 0;
`;

const Title = styled.h2`
  color: #fff;
  font-size: 1.75rem;
`
const Red = styled.span`
  color: ${props => props.theme.primaryColor};  
`
const Quote = styled.p`
  color: ${props => props.theme.primaryColor};
  font-size: 4rem;
  margin: 2rem 0;
  //margin-top: auto;
  //justify-self: center;
`

export default ({ bgImg, items, title })=> {

  return(
    <SectionCustom height="80vh" bgImg={bgImg}>
      <Container>
        <Row>
          <Col xs={12} md={8}>
            <Title>
              {title}
            </Title>
          </Col>
          <Col xs={12} md={4}>
            <Quote>
              <ClasiQuote />
            </Quote>              
            <ReviewsCarousel items={items} />          
          </Col>          
        </Row>
      </Container>
    </SectionCustom>
  )
}